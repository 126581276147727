<template>
    <v-container fluid ref="container" class="pb-0" style=" height: 100%; ">
        <v-col ref="details" class="ma-0 pa-0">
            <v-row
                style="background-color: #eeeeee; border-radius: 5px;"
                class="mt-1 mx-0 px-1"
            >
                <h2 class="mx-6 mt-1 py-2" style="color: #212121;">
                    INFORMATION
                </h2>
                <v-spacer />
                <v-icon
                    class="ma-2"
                    color="primary"
                    @click="showInformation"
                    v-if="!showWOInfo"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                    class="ma-2"
                    color="primary"
                    @click="showInformation"
                    v-else
                >
                    mdi-eye-off
                </v-icon>
            </v-row>
            <v-row
                id="virtual-scroll-table"
                no-gutters
                v-show="showWOInfo"
                class="mt-3 px-2"
            >
                <v-col cols="4">
                    <div>
                        <div class="mt-4 d-flex justify-start">
                            <p>
                                <strong>ID: </strong>
                                {{ workOrder.code.split('-')[1] }}
                            </p>
                            <v-chip
                                :color="statusColor[workOrder.status]"
                                class="ml-2 mt-n1 white--text text-capitalize"
                                style="min-width: 85px;"
                            >
                                {{ workOrder.status }}
                            </v-chip>
                            <strong
                                class="ml-2"
                                v-if="workOrder.assemblyWorkOrder != undefined"
                                >Type:
                            </strong>
                            <v-chip
                                :color="
                                    colorOption[
                                        workOrder.assemblyWorkOrder
                                            ? 'Assembly'
                                            : 'Production'
                                    ]
                                "
                                class="ml-2 mt-n1 white--text text-capitalize"
                                style="cursor: pointer; min-width: 90px;"
                                v-if="workOrder.assemblyWorkOrder != undefined"
                                @click="openTypeDialog()"
                            >
                                {{
                                    workOrder.assemblyWorkOrder
                                        ? 'Assembly'
                                        : 'Production'
                                }}
                            </v-chip>
                        </div>

                        <p>
                            <strong>CREATION DATE: </strong
                            >{{
                                ` ${
                                    workOrder.createdOn
                                        ? formatDate(
                                              workOrder.createdOn.seconds ||
                                                  workOrder.createdOn._seconds
                                          )
                                        : ''
                                }`
                            }}
                        </p>
                        <p
                            v-if="
                                workOrder.productionEnd &&
                                    (workOrder.status == 'finished' ||
                                        workOrder.status == 'closed')
                            "
                        >
                            <strong>PRODUCTION END: </strong
                            >{{
                                ` ${
                                    workorder.productionEnd
                                        ? formatDate(
                                              workOrder.productionEnd.seconds ||
                                                  workOrder.productionEnd
                                                      ._seconds
                                          )
                                        : ''
                                }`
                            }}
                        </p>
                        <p class="text-capitalize">
                            <strong>CREATED BY: </strong>
                            {{
                                ` ${
                                    workOrder.createdBy &&
                                    workOrder.createdBy.name
                                        ? workOrder.createdBy.name
                                        : workOrder.createdBy
                                }`
                            }}
                        </p>
                        <p class="text-capitalize">
                            <strong>PROJECT MANAGER:</strong
                            >{{ ` ${projectManagerName}` }}
                        </p>
                        <div class="d-flex">
                            <div class="d-flex align-center">
                                <strong>ATTACHED: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="grey lighten-3"
                                    elevation="0"
                                    x-small
                                    class="ma-1 text-capitalize grey--text text--darken-1  "
                                    v-for="(file, i) in workOrder.attachments"
                                    :key="i"
                                >
                                    <span @click="openFileAttached(file)">{{
                                        file
                                    }}</span>
                                    <v-icon
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected' ||
                                                workOrder.status == 'sent') &&
                                                !readOnlyWO
                                        "
                                        @click="deleteFile(file)"
                                        class="ml-1"
                                        small
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    @click="openWorkOrderFiles"
                                    depressed
                                    fab
                                    x-small
                                    color="primary"
                                    class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected' ||
                                            workOrder.status == 'sent') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    class="pl-xl-2 pl-lg-2 pl-md-2"
                >
                    <div>
                        <v-textarea
                            v-model="workOrder.notes"
                            prepend-icon="mdi-text"
                            prefix="Notes: "
                            hide-details
                            rows="1"
                            @blur="saveNotes"
                            :disabled="readOnlyWO"
                        >
                        </v-textarea>
                    </div>
                    <div v-if="workOrder.reprocess" class="d-flex">
                        <p class="mt-6 mr-2 mb-0"><strong>WARRANTY:</strong></p>
                        <v-checkbox
                            v-model="workOrder.warranty"
                            hide-details
                            :disabled="
                                !'inProgress-rejected-sent-approved'.includes(
                                    workOrder.status
                                )
                            "
                            @click="updateWarranty"
                        ></v-checkbox>
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="4" class="d-flex justify-end">
                    <canvas id="qr"></canvas>
                </v-col>
            </v-row>
        </v-col>

        <v-row no-gutters class="mt-8">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filterWorkOrders"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :height="`${height}px`"
                    v-if="!workOrder.assemblyWorkOrder"
                    fixed-header
                    style="overflow-y: auto;"
                    item-key="partNumberId"
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            :style="{ 'background-color': '#eeeeee' }"
                            class="mt-0 mx-0 pb-0"
                        >
                            <v-col cols="12" sm="4" class="d-flex mb-1 pb-0">
                                <h2 class="mx-4 mt-1">
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? 'ITEMS'
                                            : 'PRODUCTION ITEMS'
                                    }}
                                </h2>
                                <v-btn
                                    class="mt-1"
                                    small
                                    icon
                                    color="primary"
                                    @click="openDeleteAllItem"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-icon>mdi-delete-sweep-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer />
                            <v-col
                                cols="12"
                                sm="4"
                                class="d-flex justify-end"
                                v-if="
                                    (workOrder.status == 'inProgress' ||
                                        workOrder.status == 'rejected') &&
                                        !readOnlyWO
                                "
                            >
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openAddItem"
                                    :disabled="loading"
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'NEW ITEM'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="importFiles"
                                    :disabled="loading"
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon>
                                        mdi-login-variant
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'IMPORT'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openUploadFiles"
                                    :disabled="loading"
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon>
                                        mdi-upload
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'FILES'
                                    }}
                                </v-btn>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                class="d-flex justify-end px-0"
                                style="flex-wrap: wrap;"
                                v-if="
                                    !user.operatorRole &&
                                        workOrder.status == 'approved'
                                "
                            >
                                <div class="d-flex justify-center pr-3">
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-1"
                                        @click="openMultipleProcesses"
                                        v-if="
                                            !user.operatorRole &&
                                                updateWOProcessesPermission
                                        "
                                        :small="$vuetify.breakpoint.smAndDown"
                                    >
                                        <v-icon>
                                            mdi-swap-vertical
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'SET PROCESSES'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-1"
                                        @click="openMultipleRegister"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                user.process
                                        "
                                        :small="$vuetify.breakpoint.smAndDown"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-tools
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'REGISTER ITEMS'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-1"
                                        :small="$vuetify.breakpoint.smAndDown"
                                        @click="openMultipleSend"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                user.process
                                        "
                                    >
                                        <v-icon class="mr-1">
                                            mdi-send
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'SEND ITEMS'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-1"
                                        @click="openReceiveItems"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                user.process
                                        "
                                        :small="$vuetify.breakpoint.smAndDown"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-database-arrow-down-outline
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'RECEIVE ITEMS'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-1"
                                        @click="openMultipleQuality"
                                        v-if="
                                            markQualityRegistersPermission &&
                                                workOrder.status == 'approved'
                                        "
                                        :small="$vuetify.breakpoint.smAndDown"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-check-bold
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'QUALITY'
                                        }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <!-- HEADERS -->
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            class="py-2 mt-2"
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            :style="{ width: '80px' }"
                        />
                    </template>
                    <template v-slot:[`header.processes`]="{ header }">
                        <div class="d-flex justify-center align-center">
                            <v-text-field
                                class="py-2 mt-2"
                                :label="header.text"
                                v-model="processToFilter"
                                dense
                                :style="{ width: '80px' }"
                            />
                            <v-spacer />
                            <v-menu rounded offset-y v-if="user.process">
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                    >
                                        <v-icon>mdi-sort</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click="
                                            sortByAvailable = !sortByAvailable
                                            sortByProduced = false
                                            sortByDelivered = false
                                            sortByPending = false
                                            sortByReleased = false
                                            sortByReprocess = false
                                            sortByRejected = false
                                        "
                                        :class="{
                                            'selected-item': sortByAvailable,
                                        }"
                                    >
                                        <v-list-item-title>
                                            Sort By Available
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="
                                            sortByAvailable = false
                                            sortByProduced = !sortByProduced
                                            sortByDelivered = false
                                            sortByPending = false
                                            sortByReleased = false
                                            sortByReprocess = false
                                            sortByRejected = false
                                        "
                                        :class="{
                                            'selected-item': sortByProduced,
                                        }"
                                    >
                                        <v-list-item-title>
                                            Sort By Produced
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="
                                            sortByAvailable = false
                                            sortByProduced = false
                                            sortByDelivered = !sortByDelivered
                                            sortByPending = false
                                            sortByReleased = false
                                            sortByReprocess = false
                                            sortByRejected = false
                                        "
                                        :class="{
                                            'selected-item': sortByDelivered,
                                        }"
                                    >
                                        <v-list-item-title>
                                            Sort By Delivered
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:[`header.status`]="{ header }">
                        <div class="d-flex justify-center align-center">
                            <p class="ma-0 pa-0 mr-2">
                                {{ header.text }}
                            </p>
                            <v-spacer />
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                    >
                                        <v-icon>mdi-sort</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click="
                                            sortByPending = !sortByPending
                                            sortByReleased = false
                                            sortByReprocess = false
                                            sortByRejected = false
                                            sortByAvailable = false
                                            sortByProduced = false
                                            sortByDelivered = false
                                        "
                                        :class="{
                                            'selected-item': sortByPending,
                                        }"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="#2b81d6"
                                                >mdi-circle</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Sort By Pending
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="
                                            sortByPending = false
                                            sortByReleased = !sortByReleased
                                            sortByReprocess = false
                                            sortByRejected = false
                                            sortByAvailable = false
                                            sortByProduced = false
                                            sortByDelivered = false
                                        "
                                        :class="{
                                            'selected-item': sortByReleased,
                                        }"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="#2fbf56"
                                                >mdi-circle</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Sort By Released
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="
                                            sortByPending = false
                                            sortByReleased = false
                                            sortByReprocess = !sortByReprocess
                                            sortByRejected = false
                                            sortByAvailable = false
                                            sortByProduced = false
                                            sortByDelivered = false
                                        "
                                        :class="{
                                            'selected-item': sortByReprocess,
                                        }"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="#ffa41d"
                                                >mdi-circle</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Sort By Reprocess
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="
                                            sortByPending = false
                                            sortByReleased = false
                                            sortByReprocess = false
                                            sortByRejected = !sortByRejected
                                            sortByAvailable = false
                                            sortByProduced = false
                                            sortByDelivered = false
                                        "
                                        :class="{
                                            'selected-item': sortByRejected,
                                        }"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="#FF2610"
                                                >mdi-circle</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Sort By Rejected
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.id`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.mass`]="{ item }">
                        <p class="my-0">
                            {{ item.mass.toFixed(2) }}
                        </p>
                    </template>
                    <template v-slot:[`item.paintingArea`]="{ item }">
                        <p class="my-0">
                            {{ item.paintingArea.toFixed(2) }}
                        </p>
                    </template>
                    <template v-slot:[`item.finishAndUCCode`]="{ item }">
                        <p class="my-0">
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <span
                                        v-on="on"
                                        :style="{ cursor: 'pointer' }"
                                    >
                                        {{
                                            item.dataFinishAndUCCode &&
                                                item.dataFinishAndUCCode.name
                                        }}
                                    </span>
                                </template>
                                <span>
                                    {{
                                        item.dataFinishAndUCCode &&
                                            item.dataFinishAndUCCode.id
                                    }}
                                </span>
                            </v-tooltip>
                        </p>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <p class="my-0" :style="{ 'max-width': '250px' }">
                            {{ item.description }}
                        </p>
                    </template>

                    <template v-slot:[`item.notes`]="{ item }">
                        <p class="my-0" :style="{ 'max-width': '250px' }">
                            {{ item.notes }}
                        </p>
                    </template>
                    <template v-slot:[`item.processes`]="{ item }">
                        <div
                            v-for="(process, index) in item.productionInfo"
                            :key="process.id"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <p class="my-0 text-left" v-on="on">
                                        {{ process.name }}:
                                        {{ process.available }} -
                                        {{ process.produced || 0 }} -
                                        {{
                                            index ==
                                                item.processes.length - 1 &&
                                            workOrder.assemblyWorkOrder !=
                                                undefined
                                                ? 'NA'
                                                : process.delivered
                                        }}
                                    </p>
                                </template>
                                <span>
                                    {{ process.name }}: <br />
                                    Available =
                                    {{ process.available || 0 }} ({{
                                        ((process.received || 0) * 100) /
                                            item.quantity
                                    }}%)<br />
                                    Produced =
                                    {{ process.produced || 0 }} ({{
                                        ((process.produced || 0) * 100) /
                                            item.quantity
                                    }}%)<br />
                                    Delivered =
                                    {{
                                        index == item.processes.length - 1
                                            ? 'NA'
                                            : process.delivered
                                    }}
                                    ({{
                                        index == item.processes.length - 1
                                            ? 'NA'
                                            : ((process.delivered || 0) * 100) /
                                              item.quantity
                                    }}%)
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <div
                            v-if="
                                item.processes.includes(qualityProcess.id) ||
                                    workOrder.assemblyWorkOrder == undefined
                            "
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        :style="{
                                            'background-color': '#2b81d6',
                                        }"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateQualityPending(item) }}
                                        </p>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        :style="{
                                            'background-color': '#2fbf56',
                                        }"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateReleasedQty(item) }}
                                        </p>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        :style="{
                                            'background-color': '#ffa41d',
                                        }"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateReprocessQty(item) }}
                                        </p>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        :style="{
                                            'background-color': '#FF2610',
                                        }"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateRejectedQty(item) }}
                                        </p>
                                    </v-btn>
                                </template>
                                <span>
                                    Pending :
                                    {{ calculateQualityPending(item) || 0 }}
                                    <br />
                                    Released :
                                    {{ calculateReleasedQty(item) }} <br />
                                    Reprocess :
                                    {{ calculateReprocessQty(item) }} <br />
                                    Rejected :
                                    {{ calculateRejectedQty(item) }} <br />
                                </span>
                            </v-tooltip>
                        </div>
                        <span v-else>-</span>
                    </template>
                    <template v-slot:[`item.files`]="{ item }">
                        <div
                            class="d-flex justify-center my-0"
                            v-if="item.files != undefined"
                        >
                            <a
                                v-if="
                                    item.files.includes(
                                        `${item.partNumber}.pdf`
                                    )
                                "
                                class="mx-1"
                                @click="loadFiles(item, 'pdf')"
                            >
                                PDF
                            </a>
                            <a
                                v-if="
                                    item.files.includes(
                                        `${item.partNumber}.dxf`
                                    )
                                "
                                class="mx-1"
                                @click="loadFiles(item, 'dxf')"
                            >
                                DXF
                            </a>
                            <a
                                v-if="
                                    item.files.includes(
                                        `${item.partNumber}.stp`
                                    )
                                "
                                class="mx-1"
                                @click="loadFiles(item, 'stp')"
                            >
                                STP
                            </a>
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div>
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click="editItem(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Edit Item
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openAddItemFiles(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-upload</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Add Item Files
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openDeleteItem(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Delete Item
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="copyItem(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Copy Item
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openArrangeProcesses(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                !user.operatorRole &&
                                                updateWOProcessesPermission
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-swap-vertical</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Arrange Processes
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="registerProduction(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                item &&
                                                item.processes.length > 0 &&
                                                activateActions(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-tools</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Register Production
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="sendToPrevProcess(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                item &&
                                                item.processes.length > 0 &&
                                                activateActions(item) &&
                                                item.processes[0] !=
                                                    user.process.id
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-arrow-left-circle-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Return To Previous Process
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="sendToNextProcess(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                item &&
                                                item.processes.length > 0 &&
                                                activateActions(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-send</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Send To Next Process
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="registerQuality(item)"
                                        v-if="
                                            !readOnlyWO &&
                                                item &&
                                                item.processes.length > 0 &&
                                                markQualityRegistersPermission &&
                                                workOrder.status == 'approved'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Register Quality
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openProductionLog(item)"
                                        v-if="
                                            item &&
                                                item.processes.length > 0 &&
                                                workOrder.status != 'inProgress'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-book-cog-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Production Log
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openDeliveryLog(item)"
                                        v-if="
                                            item &&
                                                workOrder.status != 'inProgress'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-book-arrow-right-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Delivery Log
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openQualityLog(item)"
                                        v-if="
                                            item &&
                                                item.processes.length > 0 &&
                                                workOrder.status != 'inProgress'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-book-check-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Quality Log
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openDeleteItem(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                permissionToCancelWO &&
                                                (!item.production ||
                                                    item.production.length == 0)
                                        "
                                        :disabled="
                                            item.production &&
                                                item.production.length > 0
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Delete Item
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <!--FOOTER-->
                    <template v-slot:[`body.append`]>
                        <tr>
                            <td class="font-weight-bold text-center"></td>
                            <td class="font-weight-bold text-center">
                                TOTAL
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold text-center">
                                {{ totalMass.toFixed(2) }} (kgs)
                            </td>
                            <td class="font-weight-bold text-center">
                                {{ totalPaintingArea.toFixed(2) }} (m2)
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold text-center">
                                {{ totalQuantity }}
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold"></td>
                            <td
                                class="font-weight-bold"
                                v-if="
                                    workOrder.status == 'approved' ||
                                        workOrder.status == 'closed'
                                "
                            ></td>
                        </tr>
                    </template>
                </v-data-table>
                <AssemblyItems
                    v-else
                    :workOrder="workOrder"
                    :readOnlyWO="readOnlyWO"
                    :height="height"
                    :settingFinish="settingFinish"
                    :partNumbers="partNumbers"
                    @close="closeDialog"
                    @editItem="editItem"
                    @openAddItem="openAddItem"
                    @copyItem="copyItem"
                    @openAddItemFiles="openAddItemFiles"
                    @openUploadFiles="openUploadFiles"
                    @openDeleteItem="openDeleteItem"
                    @openDeleteAllItem="openDeleteAllItem"
                    @openArrangeProcesses="openArrangeProcesses"
                    @sendToNextProcess="sendToNextProcess"
                    @sendToPrevProcess="sendToPrevProcess"
                    @registerProduction="registerProduction"
                    @openMultipleProcesses="openMultipleProcesses"
                    @openMultipleRegister="openMultipleRegister"
                    @openMultipleSend="openMultipleSend"
                    @registerQuality="registerQuality"
                    @openMultipleQuality="openMultipleQuality"
                    @openProductionLog="openProductionLog"
                    @openDeliveryLog="openDeliveryLog"
                    @openQualityLog="openQualityLog"
                    @importFiles="importFiles"
                    @openReceiveItems="openReceiveItems"
                    class="ma-0 pa-0"
                />
            </v-col>
        </v-row>

        <v-row class="ma-0 pa-0" ref="buttons">
            <v-row
                no-gutters
                v-if="
                    (workOrder.status == 'inProgress' ||
                        workOrder.status == 'rejected') &&
                        !readOnlyWO
                "
                class="mt-5"
            >
                <v-col cols="12" class="d-flex">
                    <v-btn
                        v-if="
                            workOrder.projectManager == user.id ||
                                workOrder.createdBy.id == user.id
                        "
                        rounded
                        color="red"
                        class="white--text"
                        @click="openDeleteWorkOrder"
                    >
                        DELETE
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        rounded
                        color="primary"
                        @click="sendWO"
                        :loading="loading"
                        :disabled="notItemsCreated()"
                    >
                        SEND
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                no-gutters
                v-if="workOrder.status == 'sent' && !readOnlyWO"
                class="mt-5"
            >
                <v-col cols="12" class="d-flex">
                    <v-btn
                        rounded
                        color="red"
                        class="white--text"
                        @click="openRejectWO"
                        :loading="loading"
                    >
                        REJECT
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        v-if="workOrder.projectManager == user.id"
                        rounded
                        color="primary"
                        @click="approve"
                        :loading="loading"
                    >
                        APPROVE
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                no-gutters
                v-if="workOrder.status == 'approved' && !readOnlyWO"
                class="mt-5"
            >
                <v-col cols="12" class="d-flex">
                    <v-btn
                        v-if="permissionToCancelWO"
                        rounded
                        color="black"
                        @click="openCancelDialog = true"
                        :loading="loading"
                        :disabled="processesInitiated()"
                        class="white--text text-capitalize"
                    >
                        CANCEL
                    </v-btn>
                    <v-btn
                        v-if="permissionToRejectWO && permissionToApproveWO"
                        rounded
                        color="red"
                        class="white--text ml-4"
                        @click="openRejectWO"
                        :loading="loading"
                        :disabled="processesInitiated()"
                    >
                        REJECT
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        rounded
                        color="#ff9c0a"
                        @click="openClosingDialog"
                        :loading="loading"
                        class="white--text text-capitalize"
                        :disabled="disableCloseProcess()"
                    >
                        CLOSE
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>
        <!--Item Form-->
        <v-dialog
            v-model="openItemForm"
            persistent
            max-width="800px"
            :retain-focus="false"
        >
            <ItemForm
                v-if="openItemForm"
                :workOrder="workOrder"
                :partNumbers="partNumbers"
                :item="selectedItem"
                :originalItem="originalItem"
                :createForm="createForm"
                :settingProcesses="settingProcesses"
                :settingFinish="settingFinish"
                :OCs="OCs"
                :positions="positions"
                @replaceItem="replaceItem"
                @close="closeAddItem"
                @addItem="addItem"
            />
        </v-dialog>
        <!--Assembly Item Form-->
        <v-dialog
            v-model="openAssemblyItemForm"
            persistent
            max-width="800px"
            :retain-focus="false"
        >
            <AssemblyItemForm
                v-if="openAssemblyItemForm"
                :workOrder="workOrder"
                :partNumbers="partNumbers"
                :item="selectedItem"
                :originalItem="originalItem"
                :createForm="createForm"
                :settingProcesses="settingProcesses"
                :settingFinish="settingFinish"
                :OCs="OCs"
                :positions="positions"
                @replaceItem="replaceItem"
                @close="closeAddItem"
                @addItem="addItem"
            />
        </v-dialog>
        <!--Add Item Files-->
        <v-dialog
            v-model="openItemFiles"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <AddItemFiles
                v-if="openItemFiles"
                :partNumber="selectedItem.partNumber"
                :partNumbers="partNumbers"
                :workOrder="workOrder"
                :selectedItem="selectedItem"
                :settingProcesses="settingProcesses"
                :settingFinish="settingFinish"
                @openPartNumbersNotFound="openPartNumbersNotFound"
                @addItems="addItems"
                @addFiles="addFiles"
                @close="closeAddItemFiles"
            />
            <v-alert
                v-if="partNumbersNotFound.length > 0"
                type="error"
                :style="{ position: 'absolute', bottom: '0px', right: '0px' }"
            >
                <div class="d-flex">
                    <p class="my-0">
                        The following part numbers do not belong to the project:
                        <br />
                        {{ partNumbersNotFound.join(', ') }}
                    </p>
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="partNumbersNotFound = []"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>
            </v-alert>
        </v-dialog>
        <!--Upload Files-->
        <v-dialog
            v-model="uploadFiles"
            persistent
            max-width="800px"
            :retain-focus="false"
        >
            <UploadFiles
                v-if="uploadFiles"
                :workOrder="workOrder"
                @addFiles="addFiles"
                @close="closeUploadFiles"
            />
        </v-dialog>
        <!--ALERT-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--Delete Item-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5">Delete Items</v-card-title>
                <v-card-text>
                    Are you sure you want to perform this operation ?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteItem">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="removeItems"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Delete Work Order-->
        <v-dialog
            v-model="deleteWorkOrderWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWorkOrderWarning">
                <v-card-title class="text-h5">Delete Work Order</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this work order?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteWorkOrder">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteWorkOrder"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Reject Work Orders-->
        <v-dialog
            v-model="rejectWOForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectWOForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this work
                            order
                        </v-col>
                    </v-row>
                    <v-form v-model="validRejectForm">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectWO">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectWO"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!rejectedNotes"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--ADD FILES-->
        <v-dialog
            :retain-focus="false"
            v-model="workOrderFiles"
            persistent
            max-width="400px"
        >
            <v-card v-if="workOrderFiles">
                <v-card-title class="text-h5">Add File</v-card-title>
                <v-card-text>
                    <v-file-input
                        class="mt-3 pb-0"
                        v-model="file"
                        label="File"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions class="pb-3 pt-0">
                    <v-btn
                        text
                        color="secondary"
                        @click="workOrderFiles = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="saveFile"
                        color="primary"
                        :loading="loadingFiles"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--ARRANGE PROCESSES-->
        <v-dialog
            :retain-focus="false"
            v-model="arrangeProcesses"
            persistent
            max-width="450px"
        >
            <ArrangeProcesses
                v-if="arrangeProcesses"
                :item="selectedItem"
                :originalItem="originalItem"
                :settingsProcesses="settingProcesses"
                :workOrder="workOrder"
                :multipleItemSelect="false"
                @closeDialog="closeArrangeProcesses"
                @saveProcesses="saveProcesses"
            />
        </v-dialog>
        <!--REGISTER PRODUCTION-->
        <v-dialog
            :retain-focus="false"
            v-model="productionDialog"
            persistent
            max-width="500px"
        >
            <RegisterProduction
                v-if="productionDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :settings="settingProcesses"
                :user="user"
                @closeDialog="closeRegisterProduction"
            />
        </v-dialog>
        <!--RETURN TO THE PREV PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="prevProcessDialog"
            persistent
            max-width="500px"
        >
            <PrevProcess
                v-if="prevProcessDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeSendToPrevProcess"
            />
        </v-dialog>
        <!--MOVE TO THE NEXT PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="processDialog"
            persistent
            :max-width="dialogMaxWidth"
        >
            <NextProcess
                v-if="processDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :user="user"
                :sendOperation="sendOperation"
                @closeDialog="closeSendToNextProcess"
            />
        </v-dialog>
        <!--Multiple Register-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleRegisterDialog"
            persistent
            max-width="900px"
        >
            <MultipleRegister
                v-if="multipleRegisterDialog"
                :workOrder="workOrder"
                :user="user"
                :settings="settingProcesses"
                @closeDialog="closeMultipleRegister"
                @replaceItems="closeRegisterProduction"
            />
        </v-dialog>
        <!--Multiple Send-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleSendDialog"
            persistent
            max-width="900px"
        >
            <MultipleSend
                v-if="multipleSendDialog"
                :workOrder="workOrder"
                :user="user"
                :processes="settingProcesses"
                @closeDialog="closeMultipleSend"
            />
        </v-dialog>
        <!--Multiple Processes-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleProcessDialog"
            persistent
            max-width="900px"
        >
            <MultipleProcesses
                v-if="multipleProcessDialog"
                :processes="settingProcesses"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeMultipleProcesses"
            />
        </v-dialog>
        <!--Delete Item-->
        <v-dialog
            v-model="openWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openWarning">
                <v-card-title class="text-h5">
                    Close Work Order {{ workOrder.code }}</v-card-title
                >
                <v-card-text>
                    {{ message }}
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeWarning" rounded>
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeWO"
                        text
                        color="error"
                        rounded
                        :loading="loading"
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Cancel WO-->
        <v-dialog
            v-model="openCancelDialog"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openCancelDialog">
                <v-card-title class="text-h5">
                    Cancel Work Order {{ workOrder.code }}</v-card-title
                >
                <v-card-text>
                    Are you sure you want to cancel this work order ?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openCancelDialog = false"
                        rounded
                    >
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteWorkOrder"
                        text
                        color="error"
                        rounded
                        :loading="loading"
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--QUALITY PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="qualityDialog"
            persistent
            max-width="300px"
        >
            <QualityProcess
                v-if="qualityDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeQualityProcess"
            />
        </v-dialog>
        <!--MULTIPLE QUALITY PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleQualityDialog"
            persistent
            max-width="1200px"
        >
            <MultipleQualityProcess
                v-if="multipleQualityDialog"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeMultipleQualityProcess"
            />
        </v-dialog>
        <!--ITEM LOG-->
        <v-dialog
            :retain-focus="false"
            v-model="itemLogDialog"
            persistent
            max-width="1200px"
        >
            <ItemLog
                v-if="itemLogDialog"
                :viewType="viewType"
                :users="users"
                :selectedItem="selectedItem"
                @closeDialog="itemLogDialog = false"
            />
        </v-dialog>
        <!-- Type Dialog -->
        <v-dialog
            v-model="typeDialog"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="typeDialog">
                <v-card-title class="text-h5"
                    >Change Work Order Type</v-card-title
                >
                <v-card-text>
                    <v-combobox
                        v-model="workOrderType"
                        :items="options"
                        hide-details
                        label="Select Type"
                    >
                    </v-combobox>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeTypeDialog()">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="updateWorkOrderType()"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!workOrderType || workOrderType == ''"
                    >
                        SAVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import qrious from 'qrious'
import _ from 'lodash'
import { loadImage, deleteImage } from '@/helpers/imageHandler.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'

export default {
    name: 'WOGeneralInfo',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        readOnlyWO: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        ItemForm: () => import('@/components/WorkOrders/ItemForm.vue'),
        AssemblyItemForm: () =>
            import('@/components/WorkOrders/AssemblyItemForm.vue'),
        AddItemFiles: () => import('@/components/WorkOrders/AddItemFiles.vue'),
        UploadFiles: () => import('@/components/WorkOrders/UploadFiles.vue'),
        ArrangeProcesses: () =>
            import('@/components/WorkOrders/ArrangeProcesses.vue'),
        NextProcess: () => import('@/components/WorkOrders/NextProcess.vue'),
        PrevProcess: () => import('@/components/WorkOrders/PrevProcess.vue'),
        RegisterProduction: () =>
            import('@/components/WorkOrders/RegisterProduction.vue'),
        MultipleProcesses: () =>
            import('@/components/WorkOrders/MultipleProcesses.vue'),
        MultipleSend: () => import('@/components/WorkOrders/MultipleSend.vue'),
        MultipleRegister: () =>
            import('@/components/WorkOrders/MultipleRegister.vue'),
        QualityProcess: () =>
            import('@/components/WorkOrders/QualityProcess.vue'),
        MultipleQualityProcess: () =>
            import('@/components/WorkOrders/MultipleQualityProcess.vue'),
        ItemLog: () => import('@/components/WorkOrders/ItemLog.vue'),
        AssemblyItems: () =>
            import('@/components/WorkOrders/AssemblyItems.vue'),
    },
    inject: {
        approveWO: {
            from: 'approveWO',
            default() {
                return null
            },
        },
    },
    data: () => ({
        multipleRegisterDialog: false,
        openCancelDialog: false,
        workOrderFiles: false,
        createForm: false,
        originalItem: {},
        deleteWorkOrderWarning: false,
        deleteWarning: false,
        alertMessage: '',
        activateAlert: false,
        uploadFiles: false,
        selectedItem: {},
        openItemFiles: false,
        openItemForm: false,
        openAssemblyItemForm: false,
        loading: false,
        projectManagerName: undefined,
        rejectWOForm: false,
        rejectedNotes: undefined,
        validRejectForm: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PAINTING AREA',
                value: 'paintingArea',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PROCESSES',
                value: 'processes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FILES',
                value: 'files',
                align: 'center',
                sortable: false,
            },
            {
                text: 'OC',
                value: 'oc',
                align: 'left',
                sortable: false,
            },
            {
                text: 'POSITION',
                value: 'costPosition',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        statusColor: {
            sent: '#FF9800',
            approved: '#27BC4F',
            production: '#FFC000',
            rejected: 'red',
            closed: 'primary',
            inProgress: '#9E9FA0',
            finished: '#5cb85c',
        },
        colorOption: {
            Production: '#2a7fd5',
            Assembly: '#1b5289',
        },
        height: 0,
        partNumbers: [],
        partNumbersNotFound: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        settingProcesses: undefined,
        settingFinish: undefined,
        removeAll: false,
        file: null,
        loadingFiles: false,
        prevNotes: null,
        arrangeProcesses: false,
        user: {},
        processDialog: false,
        productionDialog: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        productionInfo: [],
        openWarning: false,
        multipleProcessDialog: false,
        multipleSendDialog: false,
        permissionToCancelWO: false,
        message: '',
        permissionToRejectWO: false,
        permissionToApproveWO: false,
        processToShow: undefined,
        showProcesses: false,
        packingDialog: false,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        prevProcessDialog: false,
        qualityDialog: false,
        multipleQualityDialog: false,
        viewType: null,
        itemLogDialog: false,
        users: [],
        processToFilter: undefined,
        partNumberToFilter: undefined,
        sortByAvailable: false,
        sortByProduced: false,
        sortByDelivered: false,
        sortByPending: false,
        sortByReleased: false,
        sortByReprocess: false,
        sortByRejected: false,
        updateWOProcessesPermission: false,
        markQualityRegistersPermission: false,
        height2: 0,
        sendOperation: false,
        dialogMaxWidth: '1000px',
        options: ['Production', 'Assembly'],
        typeDialog: false,
        workOrderType: '',
        OCs: [],
        positions: [],
        showWOInfo: false,
    }),
    computed: {
        filterWorkOrders() {
            let conditions = []
            let items = this.workOrder.items ? this.workOrder.items : []
            if (this.processToFilter) {
                conditions.push(this.filterByProcess)
            }
            if (this.partNumberToFilter) {
                conditions.push(this.filterByPartNumber)
            }
            if (conditions.length > 0) {
                return items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            if (
                ((this.sortByAvailable ||
                    this.sortByProduced ||
                    this.sortByDelivered) &&
                    this.user.process) ||
                this.sortByPending ||
                this.sortByReleased ||
                this.sortByReprocess ||
                this.sortByRejected
            ) {
                const auxItems = _.cloneDeep(items)
                const sortedItems = this.sort(auxItems)
                return sortedItems
            } else if (
                !this.sortByAvailable &&
                !this.sortByProduced &&
                !this.sortByDelivered &&
                !this.sortByPending &&
                !this.sortByReleased &&
                !this.sortByReprocess &&
                !this.sortByRejected
            ) {
                items.sort((a, b) => {
                    const last4DigitsA = parseInt(a.partNumber.slice(-4), 10)
                    const last4DigitsB = parseInt(b.partNumber.slice(-4), 10)
                    return last4DigitsA - last4DigitsB
                })

                return items
            }
            return items
        },
        validateOrders() {
            return !(
                this.workOrder.items?.filter(
                    item => item.files && item.files.length > 0
                ).length > 0
            )
        },
        totalMass() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.mass * item.quantity,
                      0
                  )
                : 0
        },
        totalPaintingArea() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.paintingArea * item.quantity,
                      0
                  )
                : 0
        },
        totalQuantity() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) => accumulator + item.quantity,
                      0
                  )
                : 0
        },
    },
    watch: {
        workOrder: async function() {
            this.setUpdatedData()
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.showInformation()
            this.prevNotes = this.workOrder.notes
            // set project manager
            let projectManager = undefined
            if (typeof this.workOrder.project.projectManager == 'string') {
                projectManager = await API.getUser(
                    this.workOrder.project.projectManager
                )
            } else {
                projectManager = this.workOrder.project.projectManager
            }
            this.projectManagerName = projectManager.name
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.updateWOProcessesPermission = this.user.permissions.includes(
                'updateWOProcesses'
            )

            this.markQualityRegistersPermission = this.user.permissions.includes(
                'markQualityRegisters'
            )

            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // retrieve permission to cancel a WO
            this.permissionToCancelWO = user.permissions.includes('cancelWO')
            this.permissionToRejectWO = user.permissions.includes('rejectWO')
            this.permissionToApproveWO = user.permissions.includes('approvedWO')
            // qr code
            new qrious({
                element: document.getElementById('qr'),
                value: `${process.env.VUE_APP_CLIENT_HOST}/work-orders/main-work-orders?orderId=${this.workOrder.id}&companyId=${this.companyId}&code=${this.workOrder.code}`,
            })
            await this.setOrderData()
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }

            if (
                this.workOrder.status == 'approved' ||
                this.workOrder.status == 'closed'
            ) {
                this.headers.splice(9, 0, {
                    text: 'STATUS',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                })
            }
            this.workOrderType = this.workOrder.assemblyWorkOrder
                ? 'Assembly'
                : 'Production'

            this.quotes = await API.getProjectQuotes(this.workOrder.project.id)

            // get all OCs and all positions per OC
            const { positions, OCs } = await this.getOCsAndPositions()

            this.OCs = OCs
            this.positions = positions
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getOCsAndPositions() {
            let positions = {}
            let OCs = []
            if (this.quotes.length > 0) {
                for (let quote of this.quotes) {
                    if (quote.oc) {
                        OCs.push(quote.oc)
                        let quoteCosts = await API.getCosts(quote.id)
                        if (quoteCosts.length > 0) {
                            quoteCosts.forEach(cost => {
                                if (cost.index > 0 && cost.type == 'cost') {
                                    if (!positions[quote.oc]) {
                                        positions[quote.oc] = []
                                    }
                                    positions[quote.oc].push(
                                        cost.index.toString() +
                                            ' - ' +
                                            cost.reference
                                    )
                                }
                            })
                        }
                    }
                }
            }
            // delete OCS without positions
            OCs = OCs.filter(oc => positions[oc] && positions[oc].length > 0)
            return { positions, OCs }
        },
        async updateWarranty() {
            try {
                this.loading = true
                await API.updateReprocessWorkOrder({
                    workOrderId: this.workOrder.id,
                    warranty: this.workOrder.warranty,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openClosingDialog() {
            try {
                if (!this.allItemsCompleted()) {
                    this.message =
                        'You are about to close the work order without completing all the processes, are you sure to carry out this operation?'
                } else {
                    this.message =
                        'Are you sure you want to close this work order ?'
                }
                this.openWarning = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        processesInitiated() {
            for (const item of this.workOrder.items || []) {
                if (item.production && item.production.length > 0) {
                    return true
                }
            }
            return false
        },
        async deleteFile(file) {
            try {
                this.loading = true
                await deleteFile(
                    file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/attachments`
                )
                this.workOrder.attachments = this.workOrder.attachments.filter(
                    f => f != file
                )
                await API.workOrderAttachments({
                    workOrderId: this.workOrder.id,
                    files: this.workOrder.attachments,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/attachments`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile() {
            try {
                this.loadingFiles = true
                await saveFile(
                    this.file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/attachments`
                )
                this.workOrder.attachments = [
                    ...(this.workOrder.attachments || []),
                    this.file.name,
                ]
                await API.workOrderAttachments({
                    workOrderId: this.workOrder.id,
                    files: this.workOrder.attachments,
                })
                this.closeWorkOrderFiles()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingFiles = false
            }
        },
        closeWorkOrderFiles() {
            this.workOrderFiles = false
            this.file = null
        },
        openWorkOrderFiles() {
            this.workOrderFiles = true
        },
        closeWarning() {
            this.openWarning = false
        },
        async closeWO() {
            try {
                this.loading = true
                await API.closeWorkOrder({
                    workOrderId: this.workOrder.id,
                    notificationId: 'ar0vX28u4hadZW2pVn9n',
                })
                this.activateAlert = true
                this.alertMessage = 'Work Order Successfully Closed'
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeWarning()
            }
        },
        notFoundFile() {
            let response = false
            for (const item of this.workOrder.items || []) {
                if (
                    !item.files ||
                    !item.files.includes(`${item.partNumber}.pdf`)
                ) {
                    response = true
                    break
                }
            }
            return response
        },
        notItemsCreated() {
            if (this.workOrder.assemblyWorkOrder) {
                return this.workOrder.items &&
                    this.workOrder.items.length > 0 &&
                    this.workOrder.items.every(
                        item =>
                            item.assemblyItems && item.assemblyItems.length > 0
                    )
                    ? false
                    : true
            } else {
                return this.workOrder.items && this.workOrder.items.length > 0
                    ? false
                    : true
            }
        },
        replaceItem(item) {
            // retrieve data
            if (item.processes && item.processes.length > 0) {
                item.dataProcesses = this.settingProcesses.processes.filter(
                    process => item.processes.includes(process.id)
                )
            }
            if (item.finishAndUCCode) {
                item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                    e => e.id == item.finishAndUCCode
                )
            }
            const index = this.workOrder.items.findIndex(i => i.id == item.id)
            if (index >= 0) {
                this.workOrder.items.splice(index, 1, {
                    ...this.workOrder.items[index],
                    ...item,
                })
                this.workOrder.items = [...this.workOrder.items]
            }
        },
        editItem(item) {
            this.selectedItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
            this.createForm = false
            if (this.workOrder.assemblyWorkOrder) {
                this.openAssemblyItemForm = true
            } else {
                this.openItemForm = true
            }
        },
        async deleteWorkOrder() {
            try {
                this.loading = true
                await API.deleteWorkOrder(this.workOrder.id)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteWorkOrder() {
            this.deleteWorkOrderWarning = false
        },
        openDeleteWorkOrder() {
            this.deleteWorkOrderWarning = true
        },
        async copyItem(item) {
            let copiedItem = _.cloneDeep(item)
            delete copiedItem.id
            delete copiedItem.partNumber
            delete copiedItem.partNumberId
            delete copiedItem.dataProcesses
            delete copiedItem.files
            delete copiedItem.dataFinishAndUCCode
            this.selectedItem = _.cloneDeep(copiedItem)
            this.originalItem = _.cloneDeep(copiedItem)
            this.createForm = true
            if (this.workOrder.assemblyWorkOrder) {
                this.openAssemblyItemForm = true
            } else {
                this.openItemForm = true
            }
        },
        async removeItems() {
            if (this.removeAll) {
                await this.deleteAllItems()
            } else {
                await this.deleteItem()
            }
        },
        async deleteAllItems() {
            try {
                this.loading = true
                for (let item of this.workOrder.items) {
                    if (item.files) {
                        for (const file of item.files || []) {
                            await deleteImage(
                                `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                                file
                            )
                        }
                    }
                }
                await API.deleteWorkOrderAllItems(this.workOrder.id)
                // remove local item
                this.workOrder.items = []
                this.closeDeleteItem()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteItem() {
            try {
                this.loading = true
                await API.deleteWorkOrderItem({
                    workOrderId: this.workOrder.id,
                    partNumberId: this.selectedItem.partNumberId,
                })
                if (this.selectedItem.files) {
                    for (const file of this.selectedItem.files) {
                        await deleteImage(
                            `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                            file
                        )
                    }
                }
                // remove local item
                this.workOrder.items = [
                    ...this.workOrder.items.filter(
                        item => item.id != this.selectedItem.id
                    ),
                ]
                this.closeDeleteItem()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteItem() {
            this.removeAll = false
            this.selectedItem = {}
            this.deleteWarning = false
        },
        openDeleteItem(item) {
            this.removeAll = false
            this.selectedItem = _.cloneDeep(item)
            this.deleteWarning = true
        },
        openDeleteAllItem() {
            this.removeAll = true
            this.deleteWarning = true
        },
        async loadFiles(item, extension) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                    item.partNumber + '.' + extension
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        addFiles(newItems) {
            for (const newItem of newItems) {
                const index = this.workOrder.items.findIndex(
                    item => item.id == newItem.itemId
                )
                if (index >= 0) {
                    if (
                        !this.workOrder.items[index].files ||
                        this.workOrder.items[index].files.length === 0
                    ) {
                        this.workOrder.items.splice(index, 1, {
                            ...this.workOrder.items[index],
                            files: newItem.files,
                        })
                    } else {
                        this.workOrder.items.splice(index, 1, {
                            ...this.workOrder.items[index],
                            files: [
                                ...this.workOrder.items[index].files.filter(
                                    file => !newItem.files.includes(file)
                                ),
                                ...newItem.files,
                            ],
                        })
                    }
                }
            }
            this.workOrder.items = [...this.workOrder.items]
            this.closeUploadFiles()
        },
        async addItems(items) {
            try {
                for (const item of items) {
                    this.addItem(item)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openPartNumbersNotFound(partNumbers) {
            this.partNumbersNotFound = partNumbers
        },
        async addItem(item) {
            try {
                this.loading = true
                const partNumber = this.partNumbers.find(
                    pn => pn.id === item.partNumberId
                )
                if (partNumber) {
                    item.partNumber = partNumber.code
                }
                // retrieve data
                if (this.settingProcesses.id) {
                    if (item.processes?.length > 0) {
                        item.dataProcesses = this.settingProcesses.processes.filter(
                            process => item.processes.includes(process.id)
                        )
                    }
                }
                if (this.settingFinish.id) {
                    if (item.finishAndUCCode) {
                        item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                            e => e.id == item.finishAndUCCode
                        )
                    }
                }
                if (!this.workOrder.items) {
                    this.workOrder.items = [item]
                } else {
                    this.workOrder.items.push(item)
                }
                //TODO: use local items
                this.workOrder = _.clone(this.workOrder)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openUploadFiles() {
            this.uploadFiles = true
        },
        closeUploadFiles() {
            this.uploadFiles = false
        },
        importFiles() {
            this.openItemFiles = true
        },
        openAddItemFiles(item) {
            this.selectedItem = _.cloneDeep(item)
            this.openItemFiles = true
        },
        closeAddItemFiles() {
            this.selectedItem = {}
            this.originalItem = {}
            this.openItemFiles = false
        },
        openAddItem() {
            this.createForm = true
            if (this.workOrder.assemblyWorkOrder) {
                this.openAssemblyItemForm = true
            } else {
                this.openItemForm = true
            }
        },
        closeAddItem() {
            this.createForm = false
            this.selectedItem = {}
            this.originalItem = {}
            if (this.workOrder.assemblyWorkOrder) {
                this.openAssemblyItemForm = false
            } else {
                this.openItemForm = false
            }
        },
        onResize() {
            const {
                details: { clientHeight: detailsHeight },
                buttons: { clientHeight: buttonsHeight },
                container: { clientHeight: containerHeight },
            } = this.$refs
            let detailsContainer = this.showWOInfo ? detailsHeight : -170
            this.height =
                containerHeight -
                detailsContainer -
                buttonsHeight -
                (this.$vuetify.breakpoint.smAndDown ? 350 : 300)
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async sendWO() {
            try {
                this.loading = true
                await API.updateWorkOrder({
                    workOrderId: this.workOrder.id,
                    status: 'sent',
                    notificationId: 'sZhuzxwPqvSsy2wFSK66',
                })
                this.activateAlert = true
                this.alertMessage = 'Work Order Successfully Sent for Approval'
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async approve() {
            try {
                this.loading = true
                await API.updateWorkOrder({
                    workOrderId: this.workOrder.id,
                    status: 'approved',
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                })
                this.approveWO()
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openRejectWO() {
            this.rejectWOForm = true
        },
        closeRejectWO() {
            this.rejectWOForm = false
        },
        async rejectWO() {
            try {
                this.loading = true
                await API.updateWorkOrder({
                    workOrderId: this.workOrder.id,
                    status: 'rejected',
                    rejectedNotes: this.rejectedNotes,
                    notificationId: 'wu0mfm4lOUmF0G1vdY16',
                })
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.rejectedNotes = undefined
            }
        },
        async saveNotes() {
            if (this.prevNotes != this.workOrder.notes) {
                try {
                    await API.updateWorkOrderNotes({
                        workOrderId: this.workOrder.id,
                        notes: this.workOrder.notes,
                    })
                } catch (error) {
                    this.setErrorItems({
                        source: this.$options.name,
                        message: error.message,
                    })
                }
            }
        },
        openArrangeProcesses(item) {
            this.selectedItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
            this.arrangeProcesses = true
        },
        closeArrangeProcesses() {
            this.selectedItem = {}
            this.originalItem = {}
            this.arrangeProcesses = false
        },
        saveProcesses(processes) {
            const itemIndex = this.filterWorkOrders.findIndex(
                i => i.id === this.selectedItem.id
            )
            this.filterWorkOrders[itemIndex].dataProcesses = processes
            this.filterWorkOrders[itemIndex].processes = processes.map(
                process => process.id
            )
            this.closeArrangeProcesses()
            for (let item of this.workOrder.items) {
                item.productionInfo = this.calculateFinished(item)
            }
        },
        sendToPrevProcess(item) {
            this.selectedItem = _.cloneDeep(item)
            this.dialogMaxWidth = '500px'
            this.prevProcessDialog = true
        },
        sendToNextProcess(item) {
            this.selectedItem = _.cloneDeep(item)
            this.sendOperation = true
            this.dialogMaxWidth = '500px'
            this.processDialog = true
        },
        registerProduction(item) {
            this.selectedItem = _.cloneDeep(item)
            this.productionDialog = true
        },
        closeRegisterProduction(items) {
            try {
                this.selectedItem = _.cloneDeep({})
                this.productionDialog = false
                if (items) {
                    items = Object.values(items)
                    items.forEach(item => {
                        const index = this.workOrder.items.findIndex(
                            workOrderItem => workOrderItem.id === item.id
                        )
                        if (index > -1) {
                            this.workOrder.items[index].production =
                                item.production
                            this.workOrder.items[
                                index
                            ].productionInfo = this.calculateFinished(item)
                        }
                    })
                }
                this.multipleRegisterDialog = false
            } catch (error) {
                console.log(error)
            }
        },
        closeSendToNextProcess(items) {
            this.selectedItem = _.cloneDeep({})
            this.processDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].deliveries = item.deliveries
                    }
                })
            }
        },
        closeSendToPrevProcess(items) {
            this.selectedItem = _.cloneDeep({})
            this.prevProcessDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].deliveries = item.deliveries
                    }
                })
            }
        },
        closePackingDialog(items) {
            this.selectedItem = _.cloneDeep({})
            this.packingDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].production = item.production
                        this.workOrder.items[
                            index
                        ].productionInfo = this.calculateFinished(item)
                    }
                })
            }
        },
        activateActions(item) {
            return (
                !this.loading &&
                item.processes &&
                this.user.process &&
                item.processes.includes(this.user.process.id)
            )
        },
        calculateFinished(item) {
            let productionInfo = []

            let qualityProcess = {
                id: this.qualityProcess.id,
                name: this.qualityProcess.name,
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let packingProcess = {
                id: this.packingProcess.id,
                name: this.packingProcess.name,
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }
            if (item && item.processes && this.settingProcesses) {
                item.processesToShow = _.cloneDeep(item.processes)

                if (this.processToShow) {
                    item.processesToShow = item.processesToShow.filter(
                        p => p == this.processToShow.id
                    )
                }
                if (item.processesToShow) {
                    item.processesToShow.forEach(process => {
                        const pIndex = item.processes.findIndex(
                            p => p == process
                        )

                        let totalProduced = 0
                        let totalDelivered = 0
                        let totalReceived = 0
                        if (item.production) {
                            let production = item.production.filter(
                                register => register.process.id == process
                            )
                            totalProduced = production.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        if (item.deliveries) {
                            let deliveries = item.deliveries.filter(
                                register => register.prevProcess.id === process
                            )
                            totalDelivered = deliveries.reduce(
                                (total, register) => total + register.qty,
                                0
                            )

                            if (pIndex > 0) {
                                let received = item.deliveries.filter(
                                    register =>
                                        register.nextProcess.id == process &&
                                        register.receivedBy
                                )
                                totalReceived = received.reduce(
                                    (total, register) => total + register.qty,
                                    0
                                )
                            } else {
                                totalReceived = item.quantity
                            }
                        }
                        if (pIndex == 0 && !this.workOrder.assemblyWorkOrder) {
                            totalReceived = item.quantity
                        }

                        if (pIndex == 0 && this.workOrder.assemblyWorkOrder) {
                            totalReceived = item.quantity
                            if (item.assemblyItems) {
                                Object.values(item.assemblyItems).forEach(
                                    ai => {
                                        let receivedQty = 0
                                        if (ai.deliveries) {
                                            receivedQty = ai.deliveries.reduce(
                                                (accumulator, delivery) =>
                                                    accumulator + delivery.qty,
                                                0
                                            )
                                        }
                                        const assemblyQty =
                                            receivedQty / ai.qtyPerAssembly
                                        if (assemblyQty < totalReceived) {
                                            totalReceived = assemblyQty
                                        }
                                    }
                                )
                            }
                        }

                        item.dataProcesses = this.settingProcesses.processes.filter(
                            process => item.processes.includes(process.id)
                        )
                        let processIndex = item.dataProcesses.findIndex(
                            p => p.id == process
                        )
                        if (process == this.qualityProcess.id) {
                            //quality process
                            qualityProcess.qty += item.quantity
                            qualityProcess.delivered = 'NA'
                            qualityProcess.produced =
                                this.calculateRejectedQty(item) +
                                this.calculateReleasedQty(item)
                            qualityProcess.available = this.calculateQualityPending(
                                item
                            )

                            productionInfo.push(qualityProcess)
                        } else if (process == this.packingProcess.id) {
                            //ready for packing process
                            let received = []
                            if (item.deliveries) {
                                received = item.deliveries.filter(
                                    register =>
                                        register.nextProcess.id == process &&
                                        register.receivedBy
                                )
                            }

                            totalReceived = received.reduce(
                                (total, register) => total + register.qty,
                                0
                            )

                            packingProcess.qty += item.quantity
                            packingProcess.delivered = 'NA'
                            packingProcess.produced += item.packedQty
                            packingProcess.available =
                                totalReceived - (packingProcess.produced || 0)

                            productionInfo.push(packingProcess)
                        } else if (
                            item.qtyInAssembly &&
                            pIndex == item.processes.length - 1
                        ) {
                            productionInfo.push({
                                name: item.dataProcesses[processIndex].name,
                                id: process,
                                produced:
                                    totalReceived > item.qtyInAssembly
                                        ? item.qtyInAssembly
                                        : totalReceived,
                                delivered: totalDelivered,
                                received: totalReceived,
                                available:
                                    totalReceived - item.qtyInAssembly > 0
                                        ? totalReceived - item.qtyInAssembly
                                        : 0,
                            })
                        } else {
                            productionInfo.push({
                                name: item.dataProcesses[processIndex].name,
                                id: process,
                                produced: totalProduced,
                                delivered: totalDelivered,
                                received: totalReceived,
                                available: totalReceived - totalProduced,
                            })
                        }
                    })
                }
                if (this.workOrder.assemblyWorkOrder == undefined) {
                    //quality process
                    qualityProcess.qty += item.quantity
                    qualityProcess.delivered = 'NA'
                    qualityProcess.produced =
                        this.calculateRejectedQty(item) +
                        this.calculateReleasedQty(item)
                    qualityProcess.available = this.calculateQualityPending(
                        item
                    )

                    productionInfo.push(qualityProcess)

                    let totalReceivedNew = item.deliveries
                        ? item.deliveries.reduce(
                              (total, register) =>
                                  register.nextProcess.id ==
                                      this.packingProcess.id &&
                                  register.receivedBy
                                      ? total + register.qty
                                      : total,
                              0
                          )
                        : 0

                    let totalReceivedOld = item.deliveries
                        ? item.deliveries.reduce(
                              (total, register) =>
                                  register.nextProcess.id ==
                                      this.readyForPackingProcess.id &&
                                  register.receivedBy
                                      ? total + register.qty
                                      : total,
                              0
                          )
                        : 0

                    packingProcess.qty += item.quantity
                    packingProcess.delivered = 'NA'
                    packingProcess.produced += item.packedQty
                    packingProcess.available =
                        totalReceivedOld +
                        totalReceivedNew -
                        (packingProcess.produced || 0)

                    productionInfo.push(packingProcess)
                }
            }
            return productionInfo || []
        },
        async setOrderData() {
            // set partNumber
            this.partNumbers = await API.getPartNumbers(
                this.workOrder.projectId
            )
            // set processes and finishAndUCCode
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            this.settingProcesses.processes.push(
                ...[this.qualityProcess, this.packingProcess]
            )
            this.settingFinish = settings.find(s => s.name == 'FinishAndUCCode')
            if (this.workOrder.items && this.workOrder.items.length > 0) {
                for (let item of this.workOrder.items) {
                    const partNumber = this.partNumbers.find(
                        pn => pn.id === item.partNumberId
                    )
                    if (partNumber) {
                        item.partNumber = partNumber.code
                    }
                    if (this.settingProcesses.id) {
                        if (item.processes?.length > 0) {
                            item.dataProcesses = this.settingProcesses.processes.filter(
                                process => item.processes.includes(process.id)
                            )
                        }
                    }
                    if (this.settingFinish.id) {
                        if (item.finishAndUCCode) {
                            item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                                e => e.id == item.finishAndUCCode
                            )
                        }
                    }
                    item.productionInfo = this.calculateFinished(item)
                }
            }
            this.workOrder.items = [
                ...(this.workOrder.items != undefined
                    ? this.workOrder.items
                    : []),
            ]
        },
        async setUpdatedData() {
            if (this.workOrder.items && this.workOrder.items.length > 0) {
                for (let item of this.workOrder.items) {
                    const partNumber = this.partNumbers.find(
                        pn => pn.id === item.partNumberId
                    )
                    if (partNumber) {
                        item.partNumber = partNumber.code
                    }
                    if (this.settingProcesses.id) {
                        if (item.processes?.length > 0) {
                            item.dataProcesses = this.settingProcesses.processes.filter(
                                process => item.processes.includes(process.id)
                            )
                        }
                    }
                    if (this.settingFinish.id) {
                        if (item.finishAndUCCode) {
                            item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                                e => e.id == item.finishAndUCCode
                            )
                        }
                    }
                    item.productionInfo = this.calculateFinished(item)
                }
            }
            this.workOrder.items = [
                ...(this.workOrder.items != undefined
                    ? this.workOrder.items
                    : []),
            ]
        },
        allItemsCompleted() {
            let itemsCompleted = true
            for (let item of this.workOrder.items) {
                if (item.processes) {
                    for (let process of item.processes) {
                        let totalProduced = 0
                        if (item.production) {
                            let production = item.production.filter(
                                register => register.process.id === process
                            )
                            totalProduced = production.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        if (totalProduced < item.quantity) {
                            itemsCompleted = false
                            break
                        }
                    }
                }
                if (!itemsCompleted) {
                    break
                }
            }
            return itemsCompleted
        },
        openMultipleProcesses() {
            this.multipleProcessDialog = true
        },
        closeMultipleProcesses() {
            this.multipleProcessDialog = false
        },
        openMultipleRegister() {
            this.multipleRegisterDialog = true
        },
        closeMultipleRegister() {
            this.multipleRegisterDialog = false
        },
        openMultipleSend() {
            this.multipleSendDialog = true
        },
        closeMultipleSend() {
            this.multipleSendDialog = false
        },
        disableCloseProcess() {
            //TODO: uncomment in update
            return false
            // return this.workOrder.items.some(
            //     item => item.quantity > (item.packedQty || 0)
            // )
        },
        registerQuality(item) {
            this.selectedItem = _.cloneDeep(item)
            this.qualityDialog = true
        },
        closeQualityProcess() {
            this.selectedItem = _.cloneDeep({})
            this.qualityDialog = false
        },
        openMultipleQuality() {
            this.multipleQualityDialog = true
        },
        closeMultipleQualityProcess() {
            this.multipleQualityDialog = false
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.workOrder.assemblyWorkOrder == undefined
                ) {
                    let lastProcessIndex = item.processes.length - 1
                    if (this.workOrder.assemblyWorkOrder != undefined) {
                        lastProcessIndex =
                            item.processes.findIndex(
                                p => p == this.qualityProcess.id
                            ) - 1
                    }
                    if (lastProcessIndex > -1) {
                        const lastProductionProcess =
                            item.processes[lastProcessIndex]
                        if (item.production) {
                            const production = item.production.filter(
                                entry =>
                                    entry.process.id == lastProductionProcess
                            )
                            if (production.length > 0) {
                                pendingQty = production.reduce(
                                    (accumulator, entry) =>
                                        accumulator + entry.qty,
                                    0
                                )
                            }
                        }
                        if (item.quality) {
                            const rejected = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.rejectQty || 0),
                                0
                            )
                            const released = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.releaseQty || 0),
                                0
                            )
                            pendingQty -=
                                Number(released || 0) + Number(rejected || 0)
                        }
                    }
                } else {
                    pendingQty = -1
                }

                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.workOrder.assemblyWorkOrder == undefined
                ) {
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        released += Number(value || 0)
                    }
                } else {
                    released = -1
                }

                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReprocessQty(item) {
            try {
                let reprocess = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.workOrder.assemblyWorkOrder == undefined
                ) {
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.reprocessQty || 0),
                            0
                        )
                        reprocess += Number(value || 0)
                    }
                } else {
                    reprocess = -1
                }
                return reprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.workOrder.assemblyWorkOrder == undefined
                ) {
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        rejected += Number(value || 0)
                    }
                } else {
                    rejected = -1
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openProductionLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'production'
            this.itemLogDialog = true
        },
        openDeliveryLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'delivery'
            this.itemLogDialog = true
        },
        openQualityLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'quality'
            this.itemLogDialog = true
        },
        filterByPartNumber(item) {
            return item.partNumber
                .toLowerCase()
                .includes(this.partNumberToFilter.toLowerCase())
        },
        filterByProcess(item) {
            let processesString = ''
            item.dataProcesses.forEach(process => {
                if (item.processesToShow.includes(process.id)) {
                    processesString += ` ${process.name}`
                }
            })
            return processesString
                .toLowerCase()
                .includes(this.processToFilter.toLowerCase())
        },
        sort(items) {
            if (this.user.process) {
                const process = this.user.process.id

                if (this.sortByAvailable) {
                    items.sort((a, b) => {
                        const productionA = a.productionInfo.find(
                            p => p.id === process
                        )
                        const productionB = b.productionInfo.find(
                            p => p.id === process
                        )
                        return (
                            (productionB?.available || 0) -
                            (productionA?.available || 0)
                        )
                    })
                } else if (this.sortByProduced) {
                    items.sort((a, b) => {
                        const productionA = a.productionInfo.find(
                            p => p.id === process
                        )
                        const productionB = b.productionInfo.find(
                            p => p.id === process
                        )
                        return (
                            (productionB?.produced || 0) -
                            (productionA?.produced || 0)
                        )
                    })
                } else if (this.sortByDelivered) {
                    items.sort((a, b) => {
                        const productionA = a.productionInfo.find(
                            p => p.id === process
                        )
                        const productionB = b.productionInfo.find(
                            p => p.id === process
                        )
                        return (
                            (productionB?.delivered || 0) -
                            (productionA?.delivered || 0)
                        )
                    })
                }
            }

            if (this.sortByPending) {
                items.sort((a, b) => {
                    return (
                        (this.calculateQualityPending(b) || 0) -
                        (this.calculateQualityPending(a) || 0)
                    )
                })
            } else if (this.sortByReleased) {
                items.sort((a, b) => {
                    return (
                        (this.calculateReleasedQty(b) || 0) -
                        (this.calculateReleasedQty(a) || 0)
                    )
                })
            } else if (this.sortByReprocess) {
                items.sort((a, b) => {
                    return (
                        (this.calculateReprocessQty(b) || 0) -
                        (this.calculateReprocessQty(a) || 0)
                    )
                })
            } else if (this.sortByRejected) {
                items.sort((a, b) => {
                    return (
                        (this.calculateRejectedQty(b) || 0) -
                        (this.calculateRejectedQty(a) || 0)
                    )
                })
            }

            return items
        },
        closeDialog() {
            this.$emit('close')
        },
        openReceiveItems() {
            this.sendOperation = false
            this.dialogMaxWidth = '1000px'
            this.processDialog = true
        },
        openTypeDialog() {
            this.typeDialog = true
        },
        closeTypeDialog() {
            this.typeDialog = false
        },
        async updateWorkOrderType() {
            try {
                this.loading = true
                const value = this.workOrderType == 'Production' ? false : true
                await API.updateWorkOrderType({
                    workOrderId: this.workOrder.id,
                    assemblyWorkOrder: value,
                })
                this.workOrder.assemblyWorkOrder = value
                this.closeTypeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        showInformation() {
            this.showWOInfo = !this.showWOInfo
            this.onResize()
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}

.selected-item {
    background-color: #dff0ff;
}
</style>
